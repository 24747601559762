import { AspectRatio, Box, Image, Stack, Text } from "@chakra-ui/react";
import { Slider } from "@raiden/library-ui/components/Slider";
import {
  hotelsTypeMessage,
  hotelsTypeMessagePlural,
  HOTELS_TYPES_VALUE_LIST,
  HOTELS_TYPE_VALUE_COTTAGE,
} from "@raiden/library-ui/constants/hotels";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CenteredContent } from "../../CenteredContent";
import { Href } from "../../Href";

export const HotelsTypesSlider = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/common/HotelsTypesCount").HotelsTypesCount} [hotelTypesCount]
   * @property {string} [regionCode]
   * @property {string} [departmentCode]
   */
  /**
   * @param {Props} props
   */
  function HotelsTypesSlider({ hotelTypesCount, regionCode, departmentCode }) {
    const intl = useIntl();

    const hotelTypes = useMemo(() => {
      if (hotelTypesCount !== undefined) {
        return HOTELS_TYPES_VALUE_LIST.filter(
          (hotelType) => hotelTypesCount[hotelType.id].count_hotels > 0,
        );
      }
      return HOTELS_TYPES_VALUE_LIST;
    }, [hotelTypesCount]);

    return (
      <Box>
        <CenteredContent
          maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
          <Slider
            itemWidth={{
              base: "calc(100% - (2rem / 1))",
              sm: "calc(50% - (2rem / 2))",
              md: "calc(33.33% - (2rem / 3))",
              lg: "calc(25%)",
            }}>
            {hotelTypes.map((hotelType) => (
              <Href
                key={hotelType.id}
                href={generateFrontPath({
                  id: "@front.search",
                  query: {
                    types: [hotelType.id],
                    ...(regionCode && {
                      location: { region_code: regionCode },
                    }),
                    ...(departmentCode && {
                      location: { department_code: departmentCode },
                    }),
                  },
                })}>
                <Stack
                  spacing="0"
                  borderRadius="0.625rem"
                  border="1px solid #eee"
                  overflow="hidden"
                  w="full"
                  h="full">
                  <AspectRatio ratio={516 / 290}>
                    <Image
                      src={hotelType.src}
                      alt={
                        hotelType.id === HOTELS_TYPE_VALUE_COTTAGE
                          ? intl.formatMessage({
                              defaultMessage: "Location de vacances",
                            })
                          : intl.formatMessage(hotelsTypeMessage, {
                              type: hotelType.id,
                            })
                      }
                    />
                  </AspectRatio>

                  <Box
                    flexGrow={1}
                    backgroundColor="#fff"
                    py="1rem"
                    px="1.5rem">
                    <Text variant="button-md" noOfLines={1}>
                      {hotelType.id === HOTELS_TYPE_VALUE_COTTAGE
                        ? intl.formatMessage({
                            defaultMessage: "Location de vacances",
                          })
                        : intl.formatMessage(hotelsTypeMessagePlural, {
                            type: hotelType.id,
                          })}
                    </Text>

                    <Text variant="text-xs" noOfLines={1}>
                      <FormattedMessage
                        defaultMessage="{count, plural, =0 {# annonce} one {# annonce} other {# annonces}}"
                        values={{
                          count:
                            hotelTypesCount?.[hotelType.id]?.count_hotels ?? 0,
                        }}
                      />
                    </Text>
                  </Box>
                </Stack>
              </Href>
            ))}
          </Slider>
        </CenteredContent>
      </Box>
    );
  },
);
